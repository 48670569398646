<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-center" v-if="fetchInProgress">
        <v-progress-circular
          :size="40"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" v-else-if="verifyAddress.countryId">
        <div class="error-box mt-3">
          <v-alert
            class="alert-box-outline body"
            type="success"
            text
            dense
            v-if="geoCodedAddressFlag"
            >{{ geoCodedAddress }}</v-alert
          >
          <v-alert
            class="alert-box-outline grey lighten-4 body"
            dense
            v-if="!geoCodedAddressFlag"
            >{{ geoCodedAddress }}</v-alert
          >
        </div>
        <div class="success-box pt-4">
          <p class="body mb-2">
            If the address below is correct then you don’t need to do anything.
            To change your address,
            <v-hover v-slot:default="{ hover }">
              <router-link
                to="#"
                class="text-decoration-none"
                target="_self"
                :class="hover ? 'primary--text' : 'black--text'"
                >Click here</router-link
              >
            </v-hover>
          </p>
          <v-alert class="alert-box-outline grey lighten-4 mt-4" dense>
            <p class="mb-2 body">
              {{ verifyAddress.firstname }} {{ verifyAddress.lastname }}
            </p>
            <p class="mb-2 body">{{ verifyAddress.streetNoOne }}</p>
            <p class="mb-2 body">{{ verifyAddress.streetNoTwo }}</p>
            <p class="mb-2 body">
              {{ verifyAddress.city }},
              <span v-if="stateName.length">{{ stateName[0].label }} - </span>
              {{ verifyAddress.postcode }}
            </p>
          </v-alert>
        </div>
      </v-col>
      <v-col cols="12" class="py-0" v-else>
        <v-alert type="error" class="my-2" text
          >No Address is available</v-alert
        >
      </v-col>
    </v-row>
  </div>
</template>
<script> 
import { fetchStates } from "@/services/util.service";
export default {
  name: "VerifyAddressComponent",
  props: ["geoCodedAddress", "geoCodedAddressFlag"],
  data: () => ({
    stateName: [],
  }),
  components: {},
  computed: {
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },

    verifyAddress: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.address;
      } else {
        return this.$store.state.guestInfo.address;
      }
    },
    fetchInProgress() {
      if (this.userAuth) {
        return this.$store.state.accountOne.inProgress;
      } else {
        return this.$store.state.guestInfo.fetchInProgress;
      }
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    allCountries: function () {
      return this.$store.state.accountOne.allCountries.map((countries) => {
        return {
          value: countries.id,
          label: countries.fullNameEnglish,
        };
      });
    },
    userSelectedState: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.address.regionId;
      } else {
        return this.$store.state.guestInfo.address.regionId;
      }
    },
  },
  watch: {
    userSelectedState: function () {
      this.stateNameList = fetchStates(
        this.verifyAddress.countryId,
        this.countryAndStatesList
      );
      this.stateName = this.stateNameList.filter((obj) => {
        if (obj.value == this.userSelectedState) return obj;
      });
    },
  },
  created() {
    if (this.userSelectedState) {
      this.stateNameList = fetchStates(
        this.verifyAddress.countryId,
        this.countryAndStatesList
      );
      this.stateName = this.stateNameList.filter((obj) => {
        if (obj.value == this.userSelectedState) return obj;
      });
    }
  },
};
</script>