<template>
  <v-overlay
    v-model="hideShow"
    :opacity="0.8"
    contained
    class="align-center justify-center"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <div class="h3 text-white mt-10">
            Please wait while fetching your rewards details
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            size="80"
            color="white"
            align="center"
            justify="center"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>

  <v-row v-if="zinreloTokenCheck && !hideShow">
        <v-col cols="12" class="text-center">
          <div class="h3   mt-10">
            A cloud communication error has occurred. Please wait and try again.
          </div>
        </v-col>

      </v-row>
  <div id="zrl_embed_div"></div>

</template>
<script>

import { createHelpers } from "vuex-map-fields";
import { loginInterrupt } from "@/services/nav";
const partnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField",
});
export default {
  name: "Rewards",
  data() {
    return {
      hideShow: false,
    };
  },
  computed: {
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    zinreloToken() {
      return this.$store.state.accountOne.zinreloToken;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
      zinreloTokenCheck() {
      return this.$store.state.accountOne.zinreloTokenCheck;
    },
  },

  methods: {
    zinreloInit()
    {
      window._zrl = window._zrl || [];
        let init_data = {
          partner_id: partnerId,
          jwt_token: this.zinreloToken,
          version: "v2",
          server: "https://app.zinrelo.com",
        };

         if (window.zrl_mi) window.zrl_mi.is_tab_db_added = false;
            if(!window.zrl_mi.partner_id)
            _zrl.push(["init", init_data]);
            else if(window.zrl_mi.zrl_conf_complete)
            _zrl.push(["init", init_data]);
    },
    async zinrelo() {
      try{
      if (this.zinreloToken) {
           this.hideShow = true;
        this.zinreloInit();
        setTimeout(() => {
            this.hideShow = false;
          }, 2000);
      } else {
        if (this.basicInfo) {
          this.hideShow = true;
          await this.$store.dispatch("accountOne/getZinreloToken");
          if (this.zinreloToken) {
             this.zinreloInit();
          }
        setTimeout(() => {
            this.hideShow = false;
          }, 8000);
        }
      }

    }
    catch(error)
    {
       this.hideShow = false;
    }
  }
  },

  async created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
    if (!(this.basicInfo && this.basicInfo.email)) {
      await this.$store.dispatch("accountOne/basicInfo");
      this.zinrelo();

    } else {
      this.zinrelo();
    }
  },
};
</script>
<style scoped >
#zrl_embed_div {
  height: 100%;
}
</style>
