<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" >
        <p class="mb-2 body">{{address.firstname}} {{address.lastname}}</p>
        <p class="mb-2 body" v-if="address.streetNoOne">{{address.streetNoOne}} ,{{address.streetNoTwo}} </p>
        <p class="mb-2 body" v-else>{{address.street[0]}} ,{{address.street[1]}} </p>
        <p class="mb-2 body">
          {{address.city}},
          <span
            v-if="address.region"
          >{{address.region.region}}</span>
           {{address.postcode}}
        </p>
        <p class="mb-2 body">
          <span v-if="address.countryId">{{address.countryId}}</span>
        </p>
        <p class="mb-2 body">
           T:<a :href="'tel:'+ address.telephone"> {{address.telephone}}</a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ShippingAddressComponent",
  props: ["address"],
  components: {},
  computed: {
  },
  methods: {},
  created() {}
};
</script>
