<template>
  <v-container>
   
    <v-row v-if="one.extensionAttributes">
      <v-col cols="12" class="fontcolor-black-light">
        <p class="h2 body-font text-uppercase text-primary">Newsletter Subscription</p>
        <v-row class="mt-5">
          <v-col cols="12">
            <p class="h4 body-font text-uppercase text-primary">Subscription option</p>
            <div class="news-letter-subscription-dashboard-line my-5"></div>
            <v-checkbox class="pt-5" color="primary" label="General Subscription" v-model="isSubscribed"></v-checkbox>
          </v-col>
          <v-col cols="10" class="py-1">
            <v-hover v-slot="{ isHovering, props }">
              <v-btn
                :disabled="inProgress"
                class="text-uppercase ma-1"
                v-bind="props"
                :variant="isHovering?'outlined':''"
                :class="isHovering?'text-success bg-white':'text-white bg-success'"
                size="large"
                @click="saveSubscription"
                rounded="0"
              >
                <v-progress-circular :size="20" color="success" indeterminate v-if="inProgress"></v-progress-circular>SAVE SUBSCRIPTION
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script>
import { loginInterrupt } from "@/services/nav";
import { createHelpers } from "vuex-map-fields";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField"
});
export default {
  name: "NewsLetterSubscription",
  components: { SnackbarComponent },
  data: () => ({
    //email: false,
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0
    }
  }),
  computed: {
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    ...mapFields(["one", "one.extensionAttributes.isSubscribed", "inProgress"]),
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    }
  },
  methods: {
    saveSubscription() {
      this.$store
        .dispatch("accountOne/updateSubscription")
        .then(() => {
          this.snackbar = {
            message: "Updated News Subscription Successfully",
            color: "success",
            show: true,
            timeout: 3000
          };
          setTimeout(() => {
            this.$router.push({
              name: "AccountDashboard"
            });
          }, 3000);
        }).catch(error => {
          this.snackbar = {
            message: error.message,
            color: "error",
            show: true,
            timeout: 3000
          };
          setTimeout(() => {
            this.$router.push({
              name: "AccountDashboard"
            });
          }, 3000);
        });
    }
  },
  created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
    if(!this.one.extensionAttributes)
    {
      this.$store.dispatch("accountOne/basicInfo");
    }
    
  }
};
</script>
<style src="./news_letter_subscription.scss" lang="scss" scoped />
