<template>
  <v-container>
    <v-row v-show="!orderId">
      <v-col cols="12">
        <p class="h2 body-font text-uppercase text-primary pb-2">My Orders</p>
        <div class="myorders-dashboard-line my-5"></div>
        <v-row class="mt-12" justify="center" v-if="inProgress">
          <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
        </v-row>
        <v-row no-gutters v-else>
          <v-col class="mt-7">       
              
            <v-data-table
            :headers="headers"
            :items="orderProductsList"            
            :items-per-page="5"
             class="elevation-1"
            >
            <template v-slot:item.createdAt={item}>
              <span>{{ isoStringToNormalDate(item.value.createdAt) }}</span>
            </template>
            <template v-slot:item.name={item}>
              <span>{{item.value.customerFirstname}} {{item.value.customerLastname}}</span>
            </template>
            <template v-slot:item.total={item}>
              <span>${{priceFormatter(item.value.baseGrandTotal)}}</span>
            </template>
            <template v-slot:item.actions={item}>

              <v-hover v-slot="{ isHovering, props }">
                        <router-link
                          :class="isHovering ? 'content-link-hover-color' : 'text-primary'"
                          to
                          :variant="isHovering?'outlined':''"
                          v-bind="props"
                          @click.stop="viewMyOrder(item.value.entityId)"
                          class="ls-n009 text-decoration-none body myorders-route-link"
                        >View Order</router-link>
                      </v-hover>
              
            </template>
            </v-data-table>           
           
             
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-show="orderId" no-gutters id="showInPrint">
      <v-row class="mt-12" justify="center" v-if="fetchInProgress">
        <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
      </v-row>
      <v-col cols="12" class="mt-3" v-else>
        <v-row align="center" id="showInPrintOnly" class="order-products-heading">
          <v-col cols="auto">
            <h1 class="order-product-id">Order #{{orderProduct.incrementId}}</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn class="order-product-status" tile outlined text disabled>
              <span class="text-uppercase black--text" v-if="orderProduct.status == 'failed_to_ship' || orderProduct.status == 'waiting_to_ship'">Processing Order</span>
                <span class="text-uppercase black--text" v-else>{{orderProduct.status}}</span>  
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" id="hideInPrint">
          <v-col cols="auto">
            <p class="h2 mb-0">Order #{{orderProduct.incrementId}}</p>
          </v-col>
          <v-col cols="auto">
            <v-btn class="mt-1" tile outlined text disabled>
              <span class="text-uppercase black--text" v-if="orderProduct.status == 'failed_to_ship' || orderProduct.status == 'waiting_to_ship'">Processing Order</span>
                <span class="text-uppercase black--text" v-else>{{orderProduct.status}}</span>  
            </v-btn>
          </v-col>
        </v-row>
        <p class="pt-4">{{dateString(orderProduct.createdAt)}}</p>
        <v-col class="px-0" id="hideInPrint">
         
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="isHovering ? 'content-link-hover-color' : 'text-primary'"
              to
              :variant="isHovering?'outlined':''"
              v-bind="props"
              @click.native="printOrder('showInPrint')"
              class="ls-n009 text-decoration-none float-right body myorders-route-link pr-4"
            >Print Order</router-link>
          </v-hover>
        </v-col>
        <ViewOrderTabs :viewOrderItem="orderProduct" :productInvoice="productInvoice"></ViewOrderTabs>
      </v-col>
    </v-row>
     
  </v-container>
</template>
<script>
import ViewOrderTabs from "@/components/me/my-orders/ViewOrderTabs.vue";
import { loginInterrupt } from "@/services/nav";

import {  
  priceFormatter
} from "@/services/util.service";
export default {
  name: "MyOrders",
  data: () => ({
    priceFormatter: priceFormatter,
    viewOrderItem: null,
    viewOrder: false,
    errorMessage: false,
    blue: "primary",
    white: "white",
    dialog: false,
    headers: [
      {
        title: "Order #",
        align: "start",
        sortable: false,
        key: "incrementId",
      },
      { title: "Date", key: "createdAt"  ,sortable: false},
      { title: "Ship To", key: "name" ,sortable: false},
      { title: "Estimated Total", key: "total" ,sortable: false},
      { title: "Status", key: "status" ,sortable: false},
      { title: "Action", align: "left", key: "actions" ,sortable: false }
    ],
   
  }),
  components: {
    ViewOrderTabs
  },
  computed: {
    orderId: function() {
      return this.$route.params.orderId;
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    hideAndShowFields: function() {
      let currentName = this.$route.name;
      if (currentName === "MyOrders") {
        return true;
      } else {
        return false;
      }
    },
    orderProductsList() {
      let orders = this.$store.state.meProductList.orderProductsList.items;
      if(orders.length)
        return orders.slice().reverse();
      return orders;
    },
    productInvoice() {
      return this.$store.state.meProductOne.productInvoice;
    },
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    orderProduct() {
      return this.$store.state.meProductOne.orderProduct;
    },
    inProgress() {
      return this.$store.state.meProductList.inProgress;
    },
    fetchInProgress() {
      return this.$store.state.meProductOne.fetchInProgress;
    }
  },
  methods: {
    viewMyOrder(itemId) {
      this.$store.dispatch("meProductOne/fetchOrderProduct", itemId);
      this.$router
        .push({
          name: "ViewProduct",
          params: { orderId: itemId }
        })
        .catch(error => {
          error;
        });
    },
    getOrderList() {
      this.$store.dispatch(
        "meProductList/fetchOrderProductsList",
        this.basicInfo.email
      );
    },
    printOrder(divName) {
      const prtHtml = document.getElementById(divName).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
      <head>
      ${stylesHtml}
      </head>
      <body>
      ${prtHtml}
      </body>
      </html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    isoStringToNormalDate(date) {
      let newDateTime = Date.parse(date);
      let tmp = new Date(newDateTime).toLocaleDateString().split("/");
      return (date = tmp[1] + "/" + tmp[0] + "/" + tmp[2]);
    },
    dateString(date) {
      let newDateTime = Date.parse(date);
      return new Date(newDateTime).toDateString();
    }
  },
  async created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
    this.$store.dispatch("accountOne/fetchCountries");
    await this.$store.dispatch("accountOne/basicInfo");

    if (this.orderId) {
      this.viewMyOrder(this.orderId);
    } else {
      this.$store.dispatch(
        "meProductList/fetchOrderProductsList",
        this.basicInfo.email
      );
    }
  }
};
</script>
<style>
.myorders-orderview-tabs .v-slide-group__prev {
  display: none !important;
}
.v-data-table-footer{
    display: flex;
    margin-top: 30px;
    align-items: end;
    justify-content: end;
  }
  .v-input__control {
      padding-top: 2px;
      padding-bottom: 4px;
  }
  .v-data-table-footer__items-per-page{
    display: flex;
    margin-right: 10px;
  }
  .v-data-table-footer__items-per-page > span{
    margin-right: 10px;
    padding-top: 9px
  }
  .v-data-table-footer__info{
     padding-bottom: 10px;
  }
  .v-data-table-footer__pagination{
    margin-top: -10px;
    padding-top: 4px
  }
</style>
<style src="./my_orders.scss" lang="scss" scoped />
