<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="9" lg="6" class="fontcolor-black-light">
        <v-form ref="form">
          <v-alert type="error" text v-if="errorMessageFlag">{{errorMessage}}</v-alert>
          <p class="h2 pb-2 mb-1 body-font text-uppercase text-primary">Gift Card</p>
          <div class="gift-card-line my-5"></div>
          <v-col class="px-0" v-show="cardBalance">
            <p v-show="giftCardCode" class="body-font">
              Gift Card:
              <b>{{giftCardCode}}</b>
            </p>
            <p v-show="giftCode" class="body-font">
              Gift Card:
              <b>{{giftCode}}</b>
            </p>
            <p class="body-font" v-if="giftCardBalance == 0">
              Current Balance:
              <b>$0.00</b>
            </p>
            <p class="body-font" v-else>
              Current Balance:
              <b>${{giftCardBalance}}</b>
            </p>
          </v-col>
          <v-text-field
            v-show="giftCardCode"
            label="Enter gift card code"
            ref="giftCode"
            variant="outlined"
            required
            v-model="giftCardCode"
             density="compact"
            :rules="giftCardCode?giftCodeRules:[]"
          ></v-text-field>
          <v-text-field
            v-show="!giftCardCode"
            label="Enter gift card code"
            ref="giftCode"
            variant="outlined"
            required
            v-model="giftCode"
             density="compact"
            :rules="!giftCardCode?giftCodeRules:[]"
          ></v-text-field>
          <v-row>
            <v-col cols="12">
              <v-hover v-slot="{ isHovering, props }">
                <router-link to="#" class=" ls-n009 text-decoration-none">
                  <v-btn
                    class="text-uppercase gift-card-button font-weight-400 "
                    v-bind="props"
                    :variant="isHovering?'outlined':''"
                    :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                    :block="$vuetify.display.xsOnly"
                    size="large"
                    rounded="0"
                    @click="redeemGiftCode()"
                  >REDEEM GIFT CARD</v-btn>
                </router-link>
              </v-hover>
              <v-hover v-slot="{ isHovering, props }">
                <router-link to="#" class=" ls-n009 text-decoration-none">
                  <v-btn
                    class="text-uppercase ml-sm-4 my-4 my-sm-0 gift-card-button font-weight-400 "
                    v-bind="props"
                    :variant="isHovering?'outlined':''"
                    :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                    :block="$vuetify.display.xsOnly"
                    size="large"
                    rounded="0"
                    @click="checkGiftCode()"
                  >CHECK BALANCE</v-btn>
                </router-link>
              </v-hover>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script>
import { loginInterrupt } from "@/services/nav";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";

export default {
  name: "GiftCardPage",
  data: () => ({
    errorMessageFlag: false,
    errorMessage: "",
    cardBalance: false,
    giftCode: "",
    giftCodeRules: [
      v => !!v || "This field is required",
      v => (v && v.length >= 3) || "This field must be at least 3 characters"
    ],
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0
    }
  }),
  components: {
    SnackbarComponent
  },
  computed: {
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    giftCardCode: function() {
      return this.$route.params.giftCardCode;
    },
    giftCardBalance: function() {
      return this.$store.state.storeCredit.giftCardBalance;
    },
  
    basicInfo() {
      return this.$store.state.accountOne.one;
    }
  },
  methods: {
    redeemGiftCode: async function() {
      let validForm = await this.$refs.form.validate()
      if (validForm.valid) {
        if (this.giftCardCode) {
          this.$store
            .dispatch("storeCredit/redeemGiftCard", this.giftCardCode)
            .then(() => {
              this.cardBalance = false;
              this.$refs.giftCode.reset();
              if (this.basicInfo) {
                this.$store.dispatch(
                  "storeCredit/fetchGiftCardCreditBalance",
                  this.basicInfo.id
                );
              }
              this.snackbar = {
                message: "Gift Card" + this.giftCardCode + "was redeemed.",
                color: "success",
                show: true,
                timeout: 3000
              };
            })
            .catch(error => {
              this.cardBalance = false;
              this.$refs.giftCode.reset();
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000
              };
            });
        } else {
          this.$store
            .dispatch("storeCredit/redeemGiftCard", this.giftCode)
            .then(() => {
              this.cardBalance = false;
              this.$refs.giftCode.reset();
              if (this.basicInfo) {
                this.$store.dispatch(
                  "storeCredit/fetchGiftCardCreditBalance",
                  this.basicInfo.id
                );
              }
              this.snackbar = {
                message: "Gift Card" + this.giftCode + "was redeemed.",
                color: "success",
                show: true,
                timeout: 3000
              };
            })
            .catch(error => {
              this.cardBalance = false;
              this.$refs.giftCode.reset();
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000
              };
            });
        }
      } else {
        this.errorMessage = "Please enter gift card code to redeem it."
        this.errorMessageFlag = true;
        setTimeout(() => {
          this.errorMessageFlag = false;
        }, 2000);
      }
    },
    checkGiftCode: async function() {
      let validForm = await this.$refs.form.validate()
      if (validForm.valid) {
        if (this.giftCardCode) {
          this.$store
            .dispatch("storeCredit/fetchGiftCardBalance", this.giftCardCode)
            .then(() => {
              this.cardBalance = true;
            })
            .catch(() => {
              this.cardBalance = false;
              this.snackbar = {
                message: "Please enter a valid gift card code.",
                color: "error",
                show: true,
                timeout: 3000
              };
            });
        } else {
          this.$store
            .dispatch("storeCredit/fetchGiftCardBalance", this.giftCode)
            .then(() => {
              this.cardBalance = true;
            })
            .catch(() => {
              this.cardBalance = false;
              this.snackbar = {
                message: "Please enter a valid gift card code.",
                color: "error",
                show: true,
                timeout: 3000
              };
            });
        }
      } else {
       
        this.errorMessageFlag = true;
        setTimeout(() => {
          this.errorMessageFlag = false;
        }, 2000);
      }
    }
  },
  async created() {
    await this.$store.dispatch("accountOne/basicInfo");
    
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
  }
};
</script>
<style src="./gift_card_page.scss" lang="scss" scoped />
