<template>
  <v-container>
    <v-overlay
      v-model="cartProductsProgress"
      :opacity="0.8"
      contained
      class="align-center justify-center"
    >
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="h3 text-white mt-10">
              Please wait while fetching your profile details
            </div>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="80"
              color="white"
              align="center"
              justify="center"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>

    <v-row>
      <v-col cols="12" class="pt-0">
       
        <p class="mb-8 h2 body-font text-uppercase text-primary">
          My Dashboard
        </p>
        <div>
          <p class="mt-4 h3 body-font text-uppercase text-primary">
            Account Information
          </p>
          <div class="account-dashboard-line my-5"></div>
          <v-row class="mt-5" justify="center" v-if="inProgress">
            <v-progress-circular
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="mt-4" v-else>
            <v-col cols="12" sm="6" md="6" lg="6" class="pt-0">
              <p class="body-font text-uppercase text-primary">
                Contact Information
              </p>
              <p class="mb-0 body-font">
                {{ basicInfo.firstname }} {{ basicInfo.lastname }}
              </p>
              <p class="body-font">{{ basicInfo.email }}</p>
              <v-row>
                <v-col cols="12" class="my-0 py-0">
                  <v-hover v-slot="{ isHovering, props }">
                    <router-link
                      class="text-decoration-none"
                      :to="{
                        name: 'EditAccountInfo',
                        params: { value: 'false' },
                      }"
                    >
                      <v-btn
                        rounded="0"
                        size="large"
                        v-bind="props"
                        class="mr-3 my-9 body-font account-dashboard-button"
                        :variant="isHovering ? 'outlined' : ''"
                        :class="
                          isHovering
                            ? 'text-primary bg-white'
                            : 'text-white bg-primary'
                        "
                        >EDIT</v-btn
                      >
                    </router-link>
                  </v-hover>
 
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="pt-0">
              <p class="body-font text-uppercase text-primary">Newsletters</p>
              <p
                v-if="basicInfo.extensionAttributes.isSubscribed"
                class="body-font"
              >
                You are subscribed to our "Newsletter Subscription"
              </p>
              <p v-else class="body-font">
                You are not subscribed to our "Newsletter Subscription"
              </p>
              <v-row>
                <v-col cols="12" class="mt-3 py-0">
                  <v-hover v-slot="{ isHovering, props }">
                    <router-link
                      class="text-decoration-none"
                      :to="{ name: 'NewsLetterSubscription' }"
                    >
                      <v-btn
                        size="large"
                        rounded="0"
                        v-bind="props"
                        :variant="isHovering ? 'outlined' : ''"
                        :class="
                          isHovering
                            ? 'text-primary bg-white'
                            : 'text-white bg-primary'
                        "
                        class="body-font my-6 account-dashboard-button"
                        >EDIT</v-btn
                      >
                    </router-link>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-col>
            <v-row class="mt-4">
              <p class="h3 body-font text-uppercase text-primary mb-1 mr-3">
                Address Book
              </p>
             
            </v-row>
          </v-col>
          <div class="account-dashboard-line my-1"></div>
          <v-row class="mt-6" justify="center" v-if="inProgress">
            <v-progress-circular
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="mt-4" v-else>
            <v-col cols="12" sm="6" md="6" lg="6">
              <p class="body-font text-uppercase text-primary">
                Default Billing Address
              </p>
              <p class="body-font" v-if="!billingAddressDetails.address">
                You have not set a default billing address.
              </p>
              <div v-else>
                <p class="body-font">You have set a default billing address.</p>
                <BillingAddressComponent
                  :address="billingAddressDetails.address"
                />
              </div>
              <v-row>
                <v-col cols="12">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-btn
                      @click="
                        editAddress(
                          billingAddressDetails.address,
                          billingAddressDetails.index
                        )
                      "
                      rounded="0"
                      size="large"
                      v-bind="props"
                      :variant="isHovering ? 'outlined' : ''"
                      :class="
                        isHovering
                          ? 'text-primary bg-white'
                          : 'text-white bg-primary'
                      "
                      class="body-font my-3 account-dashboard-button"
                      >EDIT</v-btn
                    >
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <p class="body-font text-uppercase text-primary">
                Default Shipping Address
              </p>
              <p class="body-font" v-if="!shippingAddressDetails.address">
                You have not set a default shipping address.
              </p>
              <div v-else>
                <p class="body-font">
                  You have set a default shipping address.
                </p>
                <ShippingAddressComponent
                  :address="shippingAddressDetails.address"
                />
              </div>
              <v-row>
                <v-col cols="12">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-btn
                      @click="
                        editAddress(
                          shippingAddressDetails.address,
                          shippingAddressDetails.index
                        )
                      "
                      rounded="0"
                      size="large"
                      v-bind="props"
                      :variant="isHovering ? 'outlined' : ''"
                      :class="
                        isHovering
                          ? 'text-primary bg-white'
                          : 'text-white bg-primary'
                      "
                      class="body-font my-9 account-dashboard-button"
                      >EDIT</v-btn
                    >
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
 
import { createHelpers } from "vuex-map-fields";
import { loginInterrupt } from "@/services/nav";
import BillingAddressComponent from "@/components/me/address/BillingAddressComponent.vue";
import ShippingAddressComponent from "@/components/me/address/ShippingAddressComponent.vue";
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField",
});
export default {
  name: "AccountDashboard",
  data() {
    return {
      hideShow: false,
    };
  },
  components: {
    BillingAddressComponent,
    ShippingAddressComponent,
  },
  computed: {
    cartProductsProgress: function () {
      return this.$store.state.userCartProduct.cartProductsProgress;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    errorMessage: function () {
      return this.$store.state.accountOne.errorMessage;
    },
    shippingAddressDetails: function () {
      return this.$store.state.accountOne.shippingAddressDetails;
    },
    billingAddressDetails: function () {
      return this.$store.state.accountOne.billingAddressDetails;
    },
    addresses: function () {
      return this.$store.state.accountOne.addresses;
    },
    inProgress: function () {
      return this.$store.state.accountOne.inProgress;
    },
     
    successMessage: function () {
      return this.$store.state.accountOne.successMessage;
    },
    ...mapFields(["newsLetterSubscription", "addressIndex"]),
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
  },
  methods: {
    editAccount() {
      this.$router.push({
        name: "EditAccountInfo",
      });
    },
    routeToPage(pageName) {
      setTimeout(() => {
        this.$router.push({
          name: pageName,
          params: { address: "shipping" },
        });
      }, 50);
    },
    editAddress(address, index) {
      let addressId = "new";
      if (index == null) {
        this.addressIndex = null;
        this.$store.dispatch("accountOne/setAddress", null);
      } else {
        this.addressIndex = index;
        addressId = address.id;
        this.$store.dispatch("accountOne/setAddress", this.addresses[index]);
      }
      localStorage.setItem("cr_meCurrentPage", "accountDashboard");
      this.$router.push({
        name: "EditAddressBook",
        params: { addressId: addressId },
      });
    },
    
  },
  created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
    this.$store.dispatch("accountOne/basicInfo");
    this.hideShow = true;
    setTimeout(() => {
      this.hideShow = false;
    }, 2000);
  },
  mounted() {},
};
</script>
<style src="./account_dashboard.scss" lang="scss" scoped />