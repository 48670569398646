<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="h2 body-font text-uppercase primary--text pb-2">Saved For Later</p>
        <div class="savedforlater-dashboard-line my-5"></div>
        <v-row class="mt-12" justify="center" v-if="inProgress">
          <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
        </v-row>
        <v-row no-gutters v-else>
          <v-col class="mt-7">
            <v-data-table
             
              :headers="headers"
              :items="savedForLaterList"
              :items-per-page="5"
              :disable-sort="true"
              :footer-props="{itemsPerPageText:'Records per page'}"
                class="elevation-1"
            >
          
           
              <template v-slot:item.startTs="{ item }">
                
                <span>{{ isoStringToNormalDate (item.raw.startTs)}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                
                  <v-row>
                  
                    <v-icon :icon="mdiEye" class="ml-4 mr-1" color="primary" @click="viewProduct(item.raw.id)"/>

                   
                      <v-icon :icon="mdiDelete" color="error" class="ml-1" @click="deleteWishProduct(item.raw,item.raw.id)"/>
                  </v-row>
              
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <ConfirmationDialog
         :key="confirmationDialog"
      :confirmationDialog="confirmationDialog"
      :confirmationDetails="confirmation"
       @actionRemove="removeProduct"
       v-if="confirmationDialog"
        ></ConfirmationDialog>
      </v-col>
    </v-row>
       <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>

  </v-container>
</template>
<script>
import { mdiEye, mdiDelete } from '@mdi/js'
import { loginInterrupt } from "@/services/nav";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
const personlizeUrl = import.meta.env.VITE_PERSONALIZE_URL;
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import {  
  priceFormatter
} from "@/services/util.service";
export default {
  name: "SavedForLater",
  components: { ConfirmationDialog ,SnackbarComponent},
  data: () => ({
    mdiEye,
    mdiDelete,
    priceFormatter: priceFormatter,
    viewOrderItem: null,
    viewOrder: false,
    errorMessage: false,
    blue: "primary",
    white: "white",
    dialog: false,
    builderSessionId: "",
    confirmationDialog: false,
    confirmation: {
      confirmationMessage: "",
    },
    itemDetails:null,
    headers: [
      {
        title: "Product Name", key: "name", sortable: false, },
      { title: "SKU",  key: "productData.sku" ,sortable: false,},
      { title: "State",  key: "state" ,sortable: false},
       { title: "Order State",  key: "orderState" ,sortable: false},
      { title: "Added Date", key: "startTs" ,sortable: false},
      { title: "Action",  align: "left", key: "actions" ,sortable: false}
    ],
      snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
  }),
  computed: {
    imageBasePathUrl() {
      return process.env.VITE_IMAGE_PATH_URL;
    },
    hideAndShowFields: function() {
      let currentName = this.$router.history.current.name;
      if (currentName === "SavedForLater") {
        return true;
      } else {
        return false;
      }
    },
    savedForLaterList() {
      let products = this.$store.state.meSavedForLaterList.savedForLaterList;
       
      if(products && products.length)
        return products.slice().reverse();
      return [];
    },
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    inProgress() {
      return this.$store.state.meSavedForLaterList.inProgress;
    },
    cartItems() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } 
    },
  },
  methods: {
    viewProduct(id) {
      window.open(`${personlizeUrl}/builder/session/resume/${id}`,"_self");
    },
    deleteWishProduct(item,itemId) {
      this.builderSessionId = itemId;
       this.itemDetails=item;
        
      (this.confirmationDialog = true),
        (this.confirmation = {
          confirmationMessage:
            "Are you sure you would like to remove this item from the wishlist?",
        });
    },
    async removeProduct(action) { 
      try {
        if (action.submit) {
          this.confirmationDialog = false;
          try {
             let builderSessionQuoteId=null;
             let quoteId=null;
             let  orderState= this.itemDetails.orderState  ;
             
                if(this.cartItems.length > 0 )
                {
                    quoteId=this.cartItems[0].quoteId
                }
                  builderSessionQuoteId=this.itemDetails && this.itemDetails.quoteData ? this.itemDetails.quoteData.quoteId : '';
                   
            if(orderState =='in_cart' && builderSessionQuoteId && quoteId &&(quoteId==builderSessionQuoteId) )
            {
              this.snackbar = {
                message: "This item is present in cart please remove from there and then try again.",
                color: "red",
                show: true,
                timeout: 3000,
              }; 
            }
             
            else{
               
            await this.$store.dispatch("meSavedForLaterList/removeWishlistProduct", {
              itemId: this.builderSessionId,
            });
            this.$store.dispatch("meSavedForLaterList/fetchSavedForLaterList",this.basicInfo.id);
           this.snackbar = {
                message: "Product Removed Successfully",
                color: "red",
                show: true,
                timeout: 3000,
              };  
            }
            
          } catch (error) {
              this.$store.dispatch("meSavedForLaterList/fetchSavedForLaterList",this.basicInfo.id);
            console.log('error',error)
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 3000,
            };
          }
        }
      } catch (error) {
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000,
        };
      }
      this.confirmationDialog = false;
      this.removeProdcutName = "";
      this.confirmation = {
        confirmationMessage: "",
      };
      this.builderSessionId = "";
    },
    isoStringToNormalDate(date) {
      let newDateTime = Date.parse(date);
      let tmp = new Date(newDateTime).toLocaleDateString().split("/");
       
      return (date = tmp[1] + "/" + tmp[0] + "/" + tmp[2]);
    },
    dateString(date) {
      let newDateTime = Date.parse(date);
      return new Date(newDateTime).toDateString();
    }
  },
  async created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }

      await this.$store.dispatch("accountOne/basicInfo");
      if(this.basicInfo && this.basicInfo.id)
      {
      
          this.$store.dispatch("meSavedForLaterList/fetchSavedForLaterList",this.basicInfo.id);
      }
  }
};
</script>
<style>
.savedforlater-orderview-tabs .v-slide-group__prev {
  display: none !important;
}
.v-data-table-footer{
    display: flex;
    margin-top: 30px;
    align-items: end;
    justify-content: end;
  }
  .v-input__control {
      padding-top: 2px;
      padding-bottom: 4px;
  }
  .v-data-table-footer__items-per-page{
    display: flex;
    margin-right: 10px;
  }
  .v-data-table-footer__items-per-page > span{
    margin-right: 10px;
    padding-top: 9px
  }
  .v-data-table-footer__info{
     padding-bottom: 10px;
  }
  .v-data-table-footer__pagination{
    margin-top: -10px;
    padding-top: 4px
  }
</style>
<style src="./saved_for_later.scss" lang="scss" scoped />
