<template>
  <v-container class="removePad">
    <v-row >
      <v-col cols="12" sm="12" class="fontcolor-black-light">
        <v-alert
          class="alert-box-outline mt-3"
          type="error"
          text
           density="compact"
          v-if="wrongPasswordMessage"
        >{{wrongPasswordMessage}}</v-alert>
        <p class="mb-1 h2 body-font text-uppercase text-primary">Edit Account Information</p>
        <v-form ref="form" v-model="valid">
          <v-row >
            <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
              <div>
                <p class="mt-4 pt-4 h3 body-font text-uppercase text-primary">Account Information</p>
                <div class="edit-account-info-line mt-5 mb-7"></div>
              <v-col cols="12" class="py-1">
                <v-text-field
                  label="First Name"
                  ref="firstname"
                  v-model="firstname"
                  :rules="textRules"
                  variant="outlined"
                  required
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-text-field
                  label="Last Name"
                  ref="lastname"
                  v-model="lastname"
                  :rules="textRules"
                  variant="outlined"
                  density="compact"
                  required
                ></v-text-field>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <v-col class="mx-0 px-0" v-if="password">
                  <v-row class="ml-1 mt-4">
                    <p class="h3 body-font">Change Password</p>
                  </v-row>
                  <v-col cols="12" class="py-1">
                  <div class="edit-account-info-line mt-5 mb-7"></div>
                  <v-text-field
                    type="password"
                    label="Current Password"
                    ref="currentPassword"
                    v-model="currentPassword"
                    :rules="passwordRules"
                    variant="outlined"
                     density="compact"
                  ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-1">
                  <v-text-field
                    type="password"
                    label="New Password"
                    ref="newPassword"
                    :rules="commonValidationRules.passwordRule"
                    v-model="newPassword"
                    variant="outlined"
                     density="compact"
                  ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-1">
                  <v-text-field
                    type="password"
                    label="Confirm New Password"
                    ref="confirmPassword"
                    v-model="confirmPassword"
                    :rules="[passwordConfirmationRule]"
                    variant="outlined"
                     density="compact"
                  ></v-text-field>
                </v-col>
            </v-col>
            </v-col>
          </v-row>
          <v-checkbox v-model="password" class="mt-0" label="Change Password" color="indigo"></v-checkbox>
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              :disabled="inProgress"
              size="large"
              v-bind="props"
              :variant="isHovering?'outlined':''"
              :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
              @click="submit"
              class="mt-1 px-12 body-font edit-account-info-button"
              rounded="0"

            >
              <v-progress-circular :size="20" color="primary" indeterminate v-if="inProgress"></v-progress-circular>SAVE
            </v-btn>
          </v-hover>
        </v-form>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script>
import { loginInterrupt } from "@/services/nav";
import { createHelpers } from "vuex-map-fields";
import { commonValidationRules } from "@/services/util.service";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField"
});
export default {
  name: "EditAccountInfo",
  data: () => ({
    wrongPasswordMessage: "",
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0
    },
    password: false,
    commonValidationRules: commonValidationRules,
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    valid: true,
    textRules: [
      v => !!v || "This field is required",
      v => (v && v.length >= 3) || "This field must be at least 3 characters"
    ],
    passwordRules: [
      v => !!v || "This field is required",
      v => (v && v.length >= 8) || "This field must be at least 8 characters"
    ]
  }),
  components: { SnackbarComponent },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
    ...mapFields([
      "changedPasswordFlag",
      "one",
      "one.firstname",
      "one.lastname",
      "inProgress"
    ]),
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    }

  },
  methods: {
    async submit() {
      let validForm = await this.$refs.form.validate()
      if ( validForm.valid && !this.password) {
        this.$store
          .dispatch("accountOne/updatePassword")
          .then(() => {
            this.snackbar = {
              message: "Updated Basic Information Successfully",
              color: "success",
              show: true,
              timeout: 3000
            };
            setTimeout(() => {
              this.$router.push({
                name: "AccountDashboard"
              });
            }, 3000);
          })
          .catch(error => {
            this.snackbar = {
              message: error,
              color: "error",
              show: true,
              timeout: 3000
            };
            setTimeout(() => {
              this.$router.push({
                name: "AccountDashboard"
              });
            }, 3000);
          });
      } else if (validForm.valid && this.password) {
        localStorage.setItem("cr_changePassowrd", true);
        this.$store
          .dispatch("accountOne/updatePassword", {
            current: this.currentPassword,
            new: this.newPassword
          })
          .then(data => {
            if (data.error == true) {
              this.wrongPasswordMessage =
                "The password doesn't match this account. Verify the password and try again.";
              this.password = false;
            } else {
              this.snackbar = {
                message: "Updated Basic Information Successfully",
                color: "success",
                show: true,
                timeout: 3000
              };
              setTimeout(() => {
                this.$router.push({
                  name: "AccountDashboard"
                });
              }, 3000);
            }
          })
          .catch(error => {
            this.snackbar = {
              message: error,
              color: "error",
              show: true,
              timeout: 3000
            };

          });
      }
    },
  },
  created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
    this.$store.dispatch("accountOne/basicInfo");
    if (this.$route.params.value === "true") {
      return (this.password = "true");
    }
  }
};
</script>

<style src="./edit_account_info.scss" lang="scss" scoped />