<template>
  <v-container>
    <v-row class="">
      <v-col cols="12" sm="12" class="fontcolor-black-light">
        <div v-show="!addressId">
          <p class="h2 mb-5 body-font text-uppercase text-primary pb-2">Address Book</p>
          <div class="address-book-line my-1"></div>
          <v-row class="mt-12" align="center" justify="center" v-if="inProgress">
            <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
          </v-row>
          <v-row class="mt-4" v-else>
            <v-col cols="12" sm="6" md="6" lg="6">
              <p
                class="body-font text-uppercase text-primary font-weight-bold"
              >Default Billing Address</p>
              <p
                class="body-font pt-4 pb-5"
                v-if="!billingAddressDetails.address"
              >You have not set a default billing address.</p>
              <div v-else>
                <p class="body-font">You have set a default billing address.</p>
                <BillingAddressComponent :address="billingAddressDetails.address" />
              </div>
              <v-row>
                <v-col cols="12">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-btn
                      @click="editAddress(billingAddressDetails.address,billingAddressDetails.index)"
                      :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                      :variant="isHovering?'outlined':''"
                      v-bind="props"
                      :large="$vuetify.display.mdAndUp?true:false"
                      class="body-font my-2 address-book-button"
                      rounded="0"
                      size="large"
                    >EDIT</v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <p
                class="body-font text-uppercase text-primary font-weight-bold"
              >Default Shipping Address</p>
              <p
                class="body-font pt-4 pb-5"
                v-if="!shippingAddressDetails.address"
              >You have not set a default shipping address.</p>
              <div v-else>
                <p class="body-font">You have set a default shipping address.</p>
                <ShippingAddressComponent :address="shippingAddressDetails.address" />
              </div>
              <v-row>
                <v-col cols="12">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-btn
                      @click="editAddress(shippingAddressDetails.address,shippingAddressDetails.index)"
                      :large="$vuetify.display.mdAndUp?true:false"
                      v-bind="props"
                      :variant="isHovering?'outlined':''"
                      :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                      class="body-font my-2 address-book-button"
                      rounded="0"
                      size="large"
                    >EDIT</v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div>
            <v-col>
              <v-row class="mt-4">
                <p
                  class="h3 body-font text-uppercase text-primary pb-2 mb-3 mr-3"
                >Additional Address Entries</p>
              </v-row>
            </v-col>
            <div class="address-book-line mt-1 mb-3"></div>
            <v-row class="mt-12" justify="center" align="center" v-if="fetchInProgress">
              <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
            </v-row>
            <v-row
              v-else-if="addresses.length < 2 && address.id==basicInfo.defaultBilling && address.id==basicInfo.defaultShipping"
            >
              <v-col>
                <p
                  class="body-font mb-1 mr-3"
                >You have no other address entries in your address book.</p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
                v-for="(address,index) in addresses"
                v-bind:key="index"
                v-show="address.id!==basicInfo.defaultBilling || address.id!==basicInfo.defaultShipping"
              >
                <ShippingAddressComponent :address="address" />
                <v-row >
                  <v-col cols="2">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-btn
                        @click="editAddress(address,index)"
                        v-bind="props"
                        density="compact"
                        :variant="isHovering?'outlined':''"
                        :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                        class="body-font my-2 address-book-button"
                        rounded="0"
                        size="x-large"
                      >EDIT</v-btn>
                    </v-hover>
                  </v-col>
                  <v-col cols="2" class="d-none d-md-block ml-6">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-btn
                        @click="deleteAddress(address)"
                        v-bind="props"
                        density="compact"
                        :variant="isHovering?'outlined':''"
                        :class="isHovering?'text-red bg-white':'text-white bg-red'"
                        class="body-font my-2 text-white"
                        rounded="0"
                        size="x-large"
                      >DELETE</v-btn>
                    </v-hover>
                  </v-col>

                  <v-col cols="2" class="  d-md-none ml-12">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-btn
                        @click="deleteAddress(address)"
                        v-bind="props"
                        density="compact"
                        :variant="isHovering?'outlined':''"
                        :class="isHovering?'text-red bg-white':'text-white bg-red'"
                        class="body-font my-2 text-white"
                        rounded="0"
                        size="x-large"
                      >DELETE</v-btn>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="my-3">
              <v-col>
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn
                    @click="editAddress()"
                    v-bind="props"
                    :large="$vuetify.display.mdAndUp?true:false"
                    :variant="isHovering?'outlined':''"
                    :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                    class="body-font my-2 address-book-button"
                    rounded="0"
                  >ADD NEW ADDRESS</v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-show="addressId">
          <p class="h2 mb-5 body-font text-uppercase text-primary">Add New Address</p>
          <v-form ref="addressBookForm" v-model="valid">
            <v-row>
              <v-col cols="12" sm="6">
                <p class="h3 body-font text-uppercase text-primary">Contact Information</p>
                <div class="address-book-line mb-5"></div>
                
                <v-form ref="contactForm" v-model="valid" :lazy-validation="lazy">
                  <v-row no-gutters>
                   
                    <v-col cols="12" class="py-1">
                      <v-text-field
                        placeholder="First Name"
                        ref="firstName"
                        v-model="firstname"
                        variant="outlined"
                        density="compact"
                        :rules="textRules"
                      
                        :rounded="5"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" class="py-1">
                      <v-text-field
                        label="Last Name"
                        ref="lastName"
                        v-model="lastname"
                        variant="outlined"
                        density="compact"
                        required
                        :rules="textRules"
                        class="address-book-contact"
                      ></v-text-field>
                      </v-col>
                     <v-col cols="12" class="py-1">
                      <v-text-field label="Company" ref="company" v-model="company" variant="outlined" density="compact"></v-text-field>
                      </v-col>
                     <v-col cols="12" class="py-1">
                      <v-text-field
                        label="Telephone"
                        ref="telephone"
                        variant="outlined"
                        density="compact"
                        :rules="phoneNumberRules"
                        v-model="telephone"
                        outlined
                        dense
                        @input="acceptNumber"
                        class="address-book-contact"
                      ></v-text-field>
                    
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" sm="6">
                <p class="h3 body-font text-uppercase text-primary">Address</p>
                <div class="address-book-line mb-5"></div>

                <ShippingAddressFormComponent
                  :hideAndShowFields="hideAndShowFields"
                  :userDetails="basicInfo.addresses"
                  :allCountries="allCountries"
                  :countryAllStates="countryAllStates"
                  ref="addressForm"
                />
                <v-checkbox
                  v-if="addressId!=basicInfo.defaultBilling"
                  v-model="defaultBilling"
                  class="pt-2"
                  hide-details
                  color="primary"
                  label="Use as my default billing address"
                ></v-checkbox>
                <v-alert
                  class="alert-box-outline mb-1"
                  type="warning"
                  text
                  density="compact"
                  v-if="addressId==basicInfo.defaultBilling"
                >This is default billing address</v-alert>
                <v-checkbox
                  v-if="addressId!=basicInfo.defaultShipping"
                  v-model="defaultShipping"
                  class="mt-0"
                  hide-details
                  color="primary"
                  label="Use as my default shipping address"
                ></v-checkbox>
                <v-alert
                  class="alert-box-outline"
                  type="warning"
                  text
                  density="compact"
                  v-if="addressId==basicInfo.defaultShipping"
                >This is default shipping address</v-alert>
              </v-col>
            </v-row>
            <v-hover v-slot="{ isHovering, props }">
              <v-btn
                class="text-uppercase address-book-button"
                :variant="isHovering?'outlined':''"
                :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                @click.stop="dialog = dialog"
                v-bind="props"
                size="large"
                @click="submit()"
                rounded="0"
              >SAVE ADDRESS</v-btn>
            </v-hover>
            <v-dialog v-model="dialog" persistent max-width="1100px">
              <v-card class="pb-0 pb-md-2">
                <v-card-title class="pb-0 pb-md-2 pr-6">
                  <v-row no-gutters>
                    <v-col cols="11">
                      <p class="mb-1 body-font text-uppercase text-primary white-space" :class="isMobileDevice ? '' : 'text-h4' ">Verify Your Address</p>
                    </v-col>

                    <v-col cols="1" class="text-right">
                      <router-link
                        to
                        class="text-decoration-none text-black fontcolor-black-light"
                      >
                        <div @click="dialog=false">X</div>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="pt-0">
                  <div class="address-book-line my-1"></div>
                  <v-row class="my-12"  justify="center" align="center" v-if="addressInProgress">
                    <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
                  </v-row>

                  <MeVerifyAddressComponent
                    v-else
                    :verifyAddress="addressFields"
                    :geoCodedAddress="geoCodedAddress"
                    :geoCodedAddressFlag="geoCodedAddressFlag"
                  />
                </v-card-text>
                <hr />
                <v-card-actions class="px-2 py-3">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-right">
                      <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                          class="text-uppercase px-3 mr-2 my-2 address-book-button"
                          :variant="isHovering ? 'outlined':''"
                          :class="isHovering?'text-primary bg-white':'text-white bg-primary'"
                          v-bind="props"
                          :size="this.$vuetify.display.smAndUp ? 'large' : 'default'"
                          @click="dialog=false"
                          rounded="0"
                        >EDIT ADDRESS</v-btn>
                      </v-hover>
                      <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                          class="text-uppercase px-3 mr-2 my-2 address-book-save-button"
                          :variant="isHovering ? 'outlined':''"
                          :class="isHovering?'text-success bg-white':'text-white bg-success'"
                          v-bind="props"
                          :size="this.$vuetify.display.smAndUp ? 'large' : 'default'"
                          @click="saveAddress"
                          :disabled="inProgress"
                          rounded="0"
                        >
                          <v-progress-circular
                            :size="20"
                            color="success"
                            indeterminate
                            v-if="inProgress"
                          ></v-progress-circular>SAVE ADDRESS
                        </v-btn>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </div>
      </v-col>
    </v-row>
 
    <ConfirmationDialog
v-if="confirmationDialog"
      :key="confirmationDialog"
      :confirmationDialog="confirmationDialog"
      :confirmationDetails="confirmation"
      @actionRemove="removeAddress"
      
    ></ConfirmationDialog>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script> 
import BillingAddressComponent from "@/components/me/address/BillingAddressComponent.vue";
import ShippingAddressComponent from "@/components/me/address/ShippingAddressComponent.vue";
import ShippingAddressFormComponent from "@/components/me/address/ShippingAddressFormComponent.vue";
import MeVerifyAddressComponent from "@/components/me/address/MeVerifyAddressComponent.vue";
 
import { loginInterrupt } from "@/services/nav";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
 
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField"
});
export default {
  name: "AddressBook",
  data() {
    return {
      setCountryCode: "US",
      phoneNumberError: false,
      confirmationDialog: false,
      confirmation: {
        confirmationMessage: ""
      },
      dialog: false,
      deleteAddressDialog: false,
      addressObj: {},
      valid: true,
      lazy: false,
      geoCodedAddress: "",
      geoCodedAddressFlag: false,
      addressInProgress: false,
      snackbar: {
        show: false,
        message: null,
        color: null,
        timeout: 0
      },
      vTelInputProps: {
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: true
        }
      },
      textRules: [
        v => !!v || "This field is required",
        v => (v && v.length >= 3) || "This field must be at least 3 characters"
      ],
      phoneNumberRules: [
        v => !!v || "Telephone is required",
        v => (v && v.length >= 8) || "Phone Number must be at least 8 characters"
      ]
    };
  },
  components: {
    ShippingAddressFormComponent,
    MeVerifyAddressComponent, 
    SnackbarComponent,
    BillingAddressComponent,
    ShippingAddressComponent,
    ConfirmationDialog, 
  },
  computed: {
    hideAndShowFields: function() {
      let currentName = this.$route.name;
      if (currentName === "AddressBook") {
        return false;
      }
      return null;
    },
    basicInfo: function() {
      return this.$store.state.accountOne.one;
    },
    addressFields: function() {
      return this.$store.state.accountOne.address;
    },
    errorMessage: function() {
      return this.$store.state.accountOne.errorMessage;
    },
    ...mapFields([
      "one",
      "address",
      "address.firstname",
      "address.lastname",
      "address.telephone",
      "address.company",
      "address.defaultShipping",
      "address.defaultBilling",
      "allCountries",
      "countryAllStates",
      "inProgress",
      "addressIndex"
    ]),
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    addressId: function() {
      return this.$route.params.addressId;
    },
    shippingAddressDetails: function() {
      return this.$store.state.accountOne.shippingAddressDetails;
    },
    billingAddressDetails: function() {
      return this.$store.state.accountOne.billingAddressDetails;
    },
    addresses: function() {
      return this.$store.state.accountOne.addresses;
    },
    address: function() {
      return this.$store.state.accountOne.address;
    },
    fetchInProgress: function() {
      return this.$store.state.accountOne.fetchInProgress;
    },
    isMobileDevice: function() {
      return this.$vuetify.display.xs ? true : false;
    }
  },
  methods: {
    acceptNumber() {
        let x = this.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
   async resetForm() {
      if (
        this.$refs.contactForm &&
        this.$refs.addressForm.$refs.shippingAddressform
      ) {
        this.$refs.contactForm.resetValidation();
        this.$refs.addressForm.$refs.shippingAddressform.resetValidation();
      }
    },
    phoneNumberValidation() {},
    submit: async function() {
      try{
       let validContactForm = await this.$refs.contactForm.validate() 
       let validShippingAddr = await this.$refs.addressForm.$refs.shippingAddressform.validate()
        if (
          validShippingAddr.valid &&
          validContactForm.valid
        ) {
          this.addressInProgress = true;
          this.dialog = true;
          let response = await this.$store
            .dispatch("accountOne/validateAddress", this.addressFields)
            if(typeof(response) == "string"){
              this.geoCodedAddress = response;
              this.geoCodedAddressFlag = false;
            }else if(typeof(response) == "object"){
              this.geoCodedAddress = "Address is geocoded.";
              this.geoCodedAddressFlag = true;
            }
          }
          this.addressInProgress = false;
      }catch(error){
        this.snackbar = {
            message: error,
            color: "error",
            show: true,
            timeout: 3000
          };
      }
    },
    saveAddress: function() {
      this.$store
        .dispatch("accountOne/updateAddress")
        .then(() => {
          this.snackbar = {
            message: "Address Updated Successfully",
            color: "success",
            show: true,
            timeout: 3000
          };
          let page = localStorage.getItem("cr_meCurrentPage");
          setTimeout(() => {
            if (page == "addressBook")
              this.$router.push({
                name: "AddressBook"
              });
            else
              this.$router.push({
                name: "AccountDashboard"
              });
          }, 1000);
        })
        .catch(error => {
          this.snackbar = {
            message: error,
            color: "error",
            show: true,
            timeout: 3000
          };
          setTimeout(() => {
            this.$router.push({
              name: "AccountDashboard"
            });
          }, 1000);
        });
      this.dialog = false;
    },
    editAddress(address, index) {
      this.resetForm();
      let addressId = "new";
      if (index == null) {
        this.addressIndex = null;
        this.$store.dispatch("accountOne/setAddress", null);
      } else {
        this.addressIndex = index;
        addressId = address.id;

        this.$store.dispatch("accountOne/setAddress", this.addresses[index]);
      }
      localStorage.setItem("cr_meCurrentPage", "addressBook");
      this.$router.push({
        name: "EditAddressBook",
        params: { addressId: addressId }
      });
    },
    deleteAddress(address) {
      
      this.addressObj = address;
      (this.confirmationDialog = true),
        (this.confirmation = {
          confirmationMessage: "Are you sure you want to delete this address?"
        });
    },
    removeAddress(action) {
      if (action.submit) {
        this.$store.dispatch("accountOne/deleteAddress", this.addressObj);
      }
      (this.confirmationDialog = false),
        (this.confirmation = {
          confirmationMessage: ""
        });
      this.addressObj = "";
    },
    validateNumber(number, data) {
      if (data.possible && data.valid) {
        this.phoneNumberError = false;
        this.telephone = "+" + data.country.dialCode +''+this.telephone
      }
      else {
        this.phoneNumberError = true;
        this.telephone = ""
      }
    }
  },
  mounted(){
    if (this.$route.params.addressId == "new") {
      this.resetForm();
    }
  },
  async created() {
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    } else {
      this.$store.dispatch("accountOne/basicInfo");
      if (this.addressId !== "new") {
        this.$store.dispatch("accountOne/basicInfo", this.addressId);
      } else {
        this.$store.dispatch("accountOne/setAddress", null);
      }
    }
  }
};
</script>
<style src="./address_book.scss" lang="scss" scopped/>