 <template>
 <div>
  <v-app-bar
    :clipped-left="$vuetify.display.mdAndUp"
    app
    color="header-background-color darken-3"
    class="bg-primary px-3"
    dark
    elevation="0"
  >
    <v-col class="d-flex justify-start pa-0">
      <v-app-bar-nav-icon @click="menuAction()" class="header-buttons" />
      <v-img
        class="ml-md-2 cursor-pointer d-none d-md-flex d-lg-flex"
        @click="goTohomePage()"
        :src="craneLogoWhite"
        max-height="45"
        max-width="100"
        contain
      ></v-img>
    </v-col>
    <v-col class="d-flex justify-center">
      <v-img
        class="ml-md-2 cursor-pointer d-md-none d-lg-none"
        @click="goTohomePage()"
        :src="craneLogoWhite"
        max-height="45"
        max-width="100"
        contain
      ></v-img>
      <div
        v-for="(categories, index) in categoryList.childrenData"
        v-bind:key="index"
      >
        <v-toolbar-items
          class="primary--text d-none d-md-flex"
          v-if="index === 0"
        >
          <v-menu
            open-on-hover
            down
            offset-y
            allow-overflow
            content-class="pt-4 header-menu-content"
            v-for="(category, index) in categories.childrenData"
            v-bind:key="index"
            v-show="category.isActive"
          >
            <template v-slot:activator="{ props }">
              <div
                v-if="
                  category.name !== 'Baby' &&
                  category.name !== 'Gifts' &&
                  category.isActive
                "
                text
                class="cursor-pointer text-uppercase my-auto body pa-0 d-inline-block px-sm-2 ls-n01 px-lg-4 me-menu-nav-link custom-menu"
                v-bind="props" 
                @click="categoryProducts(category.id, category)"
                :class="
                  category.id == activeCategoryMenu &&
                  pageName() == 'CategoryPage'
                    ? 'meheader-category-text-active'
                    : 'meheader-category-text'"
              >
                {{ category.name }}
              </div>
            </template>
            <v-col v-if="category.childrenData != ''">
              <SubMenu
                :subCategoryList="category"
                @action="inactiveCategoryMenu"
                :mainMenu="category.id"
                :subMenuItem="subMenuItem"
              />
            </v-col>
          </v-menu>
        </v-toolbar-items>
      </div>
    </v-col>
    <v-col class="d-flex justify-end pr-0">
    
      <v-btn
        icon
        class="header-buttons"
        @click="goToLogout()"
        :max-width="$vuetify.display.mdOnly ? '35px' : ''"
      >
     
        <v-icon :icon="mdiLogout"/>
      </v-btn>
      <v-btn
        icon
        @click="showSearchInput = true"
        class="d-none d-md-block header-buttons"
        :max-width="$vuetify.display.mdOnly ? '35px' : ''"
      >
     
        <v-icon :icon="mdiMagnify" class="header-action-icons"/>

      </v-btn>
      <v-btn
        icon
        class="header-buttons"
        @click="goToCartProducts()"
        :max-width="$vuetify.display.mdOnly ? '35px' : ''"
      >
       
        <v-icon :icon="mdiCartOutline"/>
        <span v-if="cartProductsProgress || inProgress">
          <v-progress-circular
            :size="20"
            color="pink"
            :width="2"
            indeterminate
          ></v-progress-circular>
        </span>
        <span v-else>
          <v-badge
            class="mb-4 badge-text-white"
            v-show="cartProductsItem.length"
            color="pink"
            :content="cartProductsItem.length"
          ></v-badge>
        </span>
      </v-btn>
    </v-col>
  </v-app-bar>
  <v-navigation-drawer
        v-model="showSearchInput"
        v-if="showSearchInput"
        color="#ffffff"
        :width="searchInputWidth"
        height="64"

      temporary
      :scrim="false"
      :location="showSearchInput ? 'right' : ''"
      :class="[
        showSearchInput
          ? 'me-headerpage-search-drawer-block'
          : 'me-headerpage-search-drawer-none',
        this.$route.name == 'HomePage' ? 'me-top-56' : 'me-top-0',
      ]"
      >
        
        <v-text-field
          v-model="search"
          autofocus
          clearable
          single-line
          placeholder="Search"
          solo
          type="text"
          color="primary"
          @blur="showSearchInput = false"
          v-on:keyup.enter="GotoPage"
          class="clickable me-header-search-field"
          variant="compact"
          :clear-icon="mdiClose"
        >
          <template v-slot:append-inner>
        
            <v-icon :icon="mdiMagnify" @click="GotoPage()" class="primary"/>
          </template>
        </v-text-field>
      </v-navigation-drawer>
      </div>
</template>
<script>
import { mdiCartOutline, mdiLogout, mdiMagnify, mdiClose } from "@mdi/js";
import SubMenu from "@/components/layout/sub-menu/SubMenu.vue";
import { doFetchCatelogProducts } from "@/services/product.service";
import {
  customAttributeValueNew,
  googleAanalyticsEventCategory,
  goToCategoryPageRouting,
  getCategoryOne,
  snakeToCamel
} from "@/services/util.service";
import craneLogoWhite from '@/assets/Crane_Logo_white-8ca28087.webp';
export default {
  name: "Header",
  components: { SubMenu },

  props: [],
  data: () => ({
    mdiCartOutline,
    mdiLogout,
    mdiMagnify,
    mdiClose,
    activeCategoryMenu: "",
    searchResultList: [],
    searchInProgress: false,
    lookupCatelog: null,
    showSearchInput: false,
    search: null,
    craneLogoWhite:craneLogoWhite
  }),
  watch: {
    lookupCatelog: {
      handler: function (newVal) {
        clearTimeout(this.lookupCatelogProductTimeoutId);
        this.lookupCatelogProductTimeoutId = setTimeout(async () => {
          try {
            if (!newVal) this.searchResultList = [];
            else {
              this.searchInProgress = true;
              let results = await doFetchCatelogProducts(newVal);
              if (results != null) {
                this.searchResultList = snakeToCamel(results);
              } else {
                this.searchResultList = [];
              }
              this.searchInProgress = false;
            }
          } catch (error) {
            this.searchResultList = [];
          }
        }, 500);
      },
    },
  },
  computed: {
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    categoryList: function () {
      return this.$store.state.headerCategoryList.categoryList;
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    subMenuItem: {
      get: function () {
        return this.$store.state.headerCategoryList.categoryId;
      },
      set: function () {},
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    cartProductsItem() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
    searchInputWidth() {
      return this.$vuetify.display.xs ? "100%" : "256";
    },
  },
  methods: {
    pageName() {
      return this.$route.name;
    },
    menuAction: function () {
      this.$emit("action");
    },
    goToCartProducts() {
      window.open("/cart-products", "_self");
    },
    goTohomePage() {
      window.open("/", "_self");
    },
    goToLogout() {
      localStorage.removeItem("cr_checkOutTab");
      window.open("/logout-success", "_self");
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValueNew(customAttributes, attributeCode);
    },
    GotoPage() {
      if (this.search) {
        this.showSearchInput = false;
        this.searchResultList = [];
        let searchKeywords = this.search;
        let pagePath = this.$route.path;
        let isMeModulePage = pagePath.includes("/me/");
        if (isMeModulePage) {
          window.open(
            `/search?pageNumber=1&pageSize=9&sortBy=relevance&q=${searchKeywords}&filter=W10=`,
            "_self"
          );
        } else {
          this.$router
            .push({
              name: "SearchPage",
              query: {
                pageNumber: 1,
                pageSize: 9,
                sortBy: "relevance",
                q: searchKeywords,
                filter: "W10=",
              },
            })
            .catch((error) => {
              error;
            });
          this.$store.dispatch("catalogBrowser/search", {
            fullCatalog: window.craneCatalog,
            keywords: searchKeywords,
            filters: [],
            pageNumber: 1,
            pageSize: 9,
            sortBy: "relevance",
          });
        }
        localStorage.removeItem("cr_subCategoryItemId");
        localStorage.removeItem("cr_subCategoryId");
        localStorage.removeItem("cr_mainCategoryId");
        this.search = null;
      }
    },
    async categoryProducts(categoryId, categoryDetails) {
      if (this.categoryDetailsList) {
        let categoryOne = await getCategoryOne(
          this.categoryDetailsList,
          categoryId
        );
        if (categoryOne) {
          this.$store.dispatch("layout/setHeroContent", categoryOne);
          let path = this.getCustomAttributeValue(categoryOne.ca, "url_path");
          console.log(categoryOne.n, categoryOne);
          this.activeCategoryMenu = categoryId;
          googleAanalyticsEventCategory(
            "browse_category",
            "ecommerce",
            categoryDetails
          );
          localStorage.setItem("cr_mainCategoryId", categoryId);
          localStorage.removeItem("cr_subCategoryItemId");
          localStorage.removeItem("cr_subCategoryId");
          let subMenuItem = "";
          this.$store.dispatch("headerCategoryList/setCategoryId", subMenuItem);
          this.$emit("actionScroll");
          let pagePath = this.$route.path;
          let isMeModulePage = pagePath.includes("/me/");
          if (isMeModulePage) {
            window.open(`/${path}`, "_self");
          } else {
            let vm = this;
            goToCategoryPageRouting(vm, categoryOne);
          }
          this.$store
            .dispatch("catalogBrowser/search", {
              fullCatalog: window.craneCatalog,
              keywords: "",
              filters: [{ label: "Category", prop: "ci", value: categoryId }],
              pageNumber: 1,
              pageSize: 9,
              sortBy: "position",
            })
            .catch((error) => {
              error;
            });
        }
      }
    },
    async inactiveCategoryMenu(data) {
      this.activeCategoryMenu = data.mainMenu;
     
      this.$store.dispatch(
        "headerCategoryList/setCategoryId",
        data.subMenuItem
      );
      localStorage.setItem("cr_mainCategoryId", data.mainMenu);
      localStorage.setItem("cr_subCategoryItemId", data.subMenuItem);
      localStorage.setItem("cr_subCategoryId", data.subMenu);
     
      if (data.subMenuItem) {
        if (this.categoryDetailsList) {
          let categoryOne = await getCategoryOne(
            this.categoryDetailsList,
            data.subMenuItem
          );
          if (categoryOne) {
            this.$store.dispatch("layout/setHeroContent", categoryOne);
            let path = this.getCustomAttributeValue(categoryOne.ca, "url_path");
            console.log(categoryOne.n, categoryOne);
         
            let pagePath = this.$route.path;
            let isMeModulePage = pagePath.includes("/me/");
            if (isMeModulePage) {
              window.open(`/${path}`, "_self");
            } else {
              let vm = this;
              goToCategoryPageRouting(vm, categoryOne);
            }
          }
        }
      }
    },
  },
  created() {},
};
</script>
<style src="./Header.scss" lang="scss" />
