<template>
  <v-container v-resize="resize">
     <v-row class="mt-12" justify="center" v-if="balanceInprogress">
      <v-progress-circular :size="60" color="primary" indeterminate></v-progress-circular>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="fontcolor-black-light">
        <p class="h2 mb-3 body-font text-uppercase text-primary">Store Credit</p>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="h4 body-font text-uppercase text-primary">Balance</p>
            <div class="storecreditinfo-dashboard-line my-5"></div>
            <p v-if="CreditBalance" class="h3 pt-2">${{giftCardCreditBalance}}</p>
            <p v-else class="h3 pt-2">$0.00</p>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="h4 body-font text-uppercase text-primary">Redeem Gift Card</p>
            <div class="storecreditinfo-dashboard-line my-5"></div>
            <p class="body body-font pt-5">
              Have a gift card?
              <v-hover v-slot="{ isHovering, props }">
                <router-link
                  to
                  v-bind="props"
                  :variant="isHovering?'outlined':''"
                  target="_self"
                  @click.native="giftCard()"

                  :class="isHovering ? 'text-primary':'black-text'"
                 > Redeem your gift card </router-link>
              </v-hover>for store credit.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="balanceHistory.length > 0">
        <p class="h4 body-font text-uppercase text-primary">Balance History</p>
        <div class="storecreditinfo-dashboard-line my-5"></div>

        <v-divider></v-divider>
        <v-table fixed-header :height="tableHeight">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center fontsize-16">Action</th>
                <th class="text-center fontsize-16">Balance Change</th>
                <th class="text-center fontsize-16">Balance</th>
                <th class="text-center fontsize-16">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(balanceDetails,index) in balanceHistory" v-bind:key="index">
                <td class="text-center fontsize-16">{{ getAction(balanceDetails.action) }}</td>
                <td class="text-center fontsize-16">{{ updateBalance(balanceDetails.balanceDelta) }}</td>
                <td class="text-center fontsize-16">{{ balanceDetails.balanceAmount }}</td>
                <td class="text-center fontsize-16">{{ isoStringToNormalDate(balanceDetails.updatedAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { loginInterrupt } from "@/services/nav";
 
export default {
  name: "StoreCreditPage",
  data: () => ({
    CreditBalance: false,
    tableHeight: ""
  }),
  computed: {
   
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    giftCardCreditBalance: function() {
      return this.$store.state.storeCredit.giftCardCreditBalance;
    },
    balanceInprogress: function() {
      return this.$store.state.storeCredit.inProgress;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
    balanceHistory: function() {
      return this.$store.state.storeCredit.balanceHistory;
    }
  },
  methods: {
    giftCard() {
      this.$router.push({
        name: "GiftCardPage"
      });
    },
    getAction(number) {
      if (number == "1") {
        return "Updated";
      } else if (number == "2") {
        return "Created";
      } else if (number == "3") {
        return "Used";
      }
    },
    updateBalance(balance) {
      if (balance.includes("-")) {
        return balance.slice(0, 1) + "$" + balance.slice(1);
      } else {
        return balance.slice(0, 0) + "$" + balance.slice(0);
      }
    },
    isoStringToNormalDate(date) {
      let newDateTime = Date.parse(date);
      let tmp = new Date(newDateTime).toLocaleString().split("/");
      return (date = tmp[1] + "/" + tmp[0] + "/" + tmp[2]);
    },
    resize() {
      this.tableHeight = window.innerHeight - 430;
    }
  },
  async created() {
    await this.$store.dispatch("accountOne/basicInfo");
    if (this.basicInfo) {
      this.$store
        .dispatch("storeCredit/fetchGiftCardCreditBalance", this.basicInfo.id)
        .then(() => {
          this.CreditBalance = true;
          this.$store.dispatch(
            "storeCredit/fetchBalanceHistory",
            this.basicInfo.id
          );
        })
        .catch(() => {
          this.CreditBalance = false;
         
        });
    }
    if (!this.userAuth) {
      loginInterrupt(
        window.location.href,
        "There was an access violation caused by your request. You need to sign back in please."
      );
    }
  }
};
</script>
<style src="./store_credit_info_page.scss" lang="scss" scoped />