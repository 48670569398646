<template>
  <v-app>
    <Header @action="onAction" />
    <v-navigation-drawer
      v-model="showMenu"
      v-if="showMenu"
      :clipped="$vuetify.display.mdAndUp"
      app
      class="text-white bg-primary pa-4"
    >
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 mt-3 my-1"
              :to="{ name: 'AccountDashboard' }"
              v-bind="props"
              >Account Dashboard</router-link
            >
          </v-hover>
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="{ name: 'MyOrders' }"
              v-bind="props"
              >My Orders</router-link
            >
          </v-hover>

          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="{ name: 'SavedForLater' }"
              v-bind="props"
              >Saved For Later</router-link
            >
          </v-hover>

          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="
                basicInfo.addresses && basicInfo.addresses.length
                  ? { name: 'AddressBook' }
                  : { name: 'EditAddressBook', params: { addressId: 'new' } }
              "
              v-bind="props"
              >Address Book</router-link
            >
          </v-hover>
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="{ name: 'EditAccountInfo', params: { value: 'false' } }"
              v-bind="props"
              >Account Information</router-link
            >
          </v-hover>
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="{ name: 'StoreCreditInfoPage' }"
              v-bind="props"
              >Store Credit</router-link
            >
          </v-hover>
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              v-if="showZinreloRewards && showZinreloRewards == 'on'"
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="my-account-link d-block text-decoration-none h4 pa-2 px-6 my-1"
              :to="{ name: 'Rewards' }"
              v-bind="props"
              >Loyalty Rewards</router-link
            >
          </v-hover>
          <v-hover v-slot="{ isHovering, props }">
            <router-link
              :class="
                isHovering ? 'text-primary darken-3 bg-white' : 'text-white'
              "
              class="d-block text-decoration-none h4 pa-2 px-6 my-1"
              to
              @click.native="goToLogout()"
              v-bind="props"
              >Logout</router-link
            >
          </v-hover>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-main>
      <router-view class="pa-4 pt-8" />
    </v-main>
  </v-app>
</template>


<script>
import Header from "@/components/me/layout/header/Header.vue";
import { createHelpers } from "vuex-map-fields";
import { setDdRumUser } from "@/services/util.service";
const { mapFields } = createHelpers({
  getterType: "layout/getField",
  mutationType: "layout/updateField",
});

export default {
  name: "Layout",

  components: {
    Header,
  },

  props: {
    source: String,
  },
  data: () => ({
    showMenu: true,
    visitorId: null,
    errorMessage: null,
  }),
  computed: {
    isDesktop: function () {
      return this.$vuetify.display.xsOnly ? true : false;
    },
    basicInfo: function () {
      return this.$store.state.accountOne.one;
    },
    orderProductsList() {
      return this.$store.state.meProductList.orderProductsList;
    },
    showZinreloRewards: function () {
      return this.$store.state.layout.showZinreloRewards;
    },
    ...mapFields(["user", "user.userVisits"]),
  },
  methods: {
    goToLogout() {
      localStorage.removeItem("cr_checkOutTab");
      window.open("/logout-success", "_self");
    },
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    onAction() {
      this.showMenu = !this.showMenu;
    },
    OnResize() {},
    async setFingerprintUserId()
    {
      try{
     
       const visitorData = await this.$fpjs.getVisitorData({
            extendedResult: true,
          });
           
      if(visitorData)
      {
        //this.$store.dispatch("accountOne/setFingerprintWithUserId",{requestId:visitorData.requestId,userId:this.basicInfo.id});
          setDdRumUser({ basicInfo: { fingerprintId: visitorData.visitorId , id: this.basicInfo.id,email: this.basicInfo.email,firstname: this.basicInfo.firstname,lastname: this.basicInfo.lastname} });
      }
      }
      catch (error) {
      console.error("setFingerprintUserId Pro Error:", error);
    }
    },
  },
  
  async created() {
    this.$store.dispatch("userCartProduct/cartProductList");
    this.$store.dispatch("headerCategoryList/fetchCategoryList");
    await this.$store.dispatch("accountOne/basicInfo");
    this.setFingerprintUserId();
   // setDdRumUser({ basicInfo: this.basicInfo });
     /* let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute(
              "src",
              "https://cdn.zinrelo.com/js/all.js"
            );
            document.head.appendChild(recaptchaScript);*/
  },
};
</script>
<style>
.router-link-active.my-account-link {
  color: #2850da !important;
  caret-color: #2850da !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
</style>
<style lang="scss">
</style>